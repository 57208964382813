$ = jQuery

function btnUp() {
    const btn = $('#btnTtop');

    $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
            btn.addClass('show');
        } else {
            btn.removeClass('show');
        }
    });
}

function mobileMenu() {
    const menuBtn = $('#menuBtn');
    const menuWrapper = $('#menuWrapper');
    const btnClose = $('#btnClose');
    const overlay = $('#overlay');


    menuBtn.on('click', function () {
        openMenu();
    });

    btnClose.on('click', function () {
        closeMenu();
    });

    overlay.on('click', function () {
        closeMenu();
    });


    function openMenu() {
        overlay.addClass('opened');
        menuWrapper.addClass('opened');
        $('body').addClass('overflow-hidden');
        $('html').addClass('overflow-hidden');
    }

    function closeMenu() {
        overlay.removeClass('opened');
        menuWrapper.removeClass('opened');
        $('body').removeClass('overflow-hidden');
        $('html').removeClass('overflow-hidden');
    }

}

function btnUp() {
    const btn = $('#btnTtop');

    $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
            btn.addClass('show');
        } else {
            btn.removeClass('show');
        }
    });
}

function accordion() {
    $('.roadmap-item').click(function () {
        $(this).find('.roadmap-title').toggleClass('active');
        $(this).find('.roadmap-description').stop().toggle('fast');
    });
}

function bootVideo() {
    const sourceElement = $(heroVideo).find('source');
    const dataSrc = sourceElement.data('url');

    setTimeout(() => {
        sourceElement.attr('src', dataSrc);
        $(heroVideo).get(0).load();
        $(heroVideo).get(0).play();
    }, 2000);
}

$(document).ready(function () {
    const heroVideo = $('#heroVideo');

    function truncateText(selector, maxLength) {
        $(selector).each(function() {
            let element = $(this);
            let truncated = element.text();
    
            if (truncated.length > maxLength) {
                truncated = truncated.substr(0, maxLength) + '...';
                element.text(truncated);
            }
        });
    }
    
    truncateText('.tours-item-title', 60);

    function initializeDatepicker() {
        $('#datepicker').datepicker({
            dateFormat: 'yy-mm-dd',
            changeYear: true,
            changeMonth: true,
            yearRange: "-100:+10",
            onSelect: function(dateText) {
                const date = $(this).datepicker('getDate');    
                const formattedDate = $.datepicker.formatDate('d MM yy', date, {
                    monthNames: ['січня', 'лютого', 'березня', 'квітня', 'травня', 'червня', 'липня', 'серпня', 'вересня', 'жовтня', 'листопада', 'грудня']
                });
                
                $(this).val(formattedDate);

                loadPosts(dateText, 1);
            }
        });
    }
    
    function loadPosts(dateText, paged) {
        var formattedDate = $('#datepicker').val(); // Збереження відформатованої дати
    
        $.ajax({
            url: ajax_object.ajax_url,
            type: 'POST',
            data: {
                action: 'filter_posts',
                date: dateText,
                paged: paged || 1 // За замовчуванням сторінка 1
            },
            success: function(response) {
                $('.exchange-content').html(response);
    
                // Повторна ініціалізація datepicker після завантаження нового контенту
                initializeDatepicker();
    
                // Встановлення відформатованої дати після завантаження
                $('#datepicker').val(formattedDate);
    
                // Повторна ініціалізація обробки кліків для пагінації
                $('.pagination a').on('click', function(e) {
                    e.preventDefault();
                    const page = $(this).attr('href').split('paged=')[1];
                    loadPosts(dateText, page);
                });
            },
        });
    }

    $('.hero-slider').slick({
        autoplay: true,
        dots: true,
        dotsClass: 'slick-dots',
        arrows: false,
    });


    $('#feedbackSlider').slick({
        dots: false,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
        accessibility: true,
        responsive: [

            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    });


    const countrySlider = $('.tour-slider-left').slick({
        autoplay: false,
        slidesToShow: 1,
        arrows: true,
    });

    $('.tour-slider-thumb').click(function () {
        countrySlider.slick('slickGoTo', $(this).data('index'))
    });

    $('.countries-slider').slick({
        slidesToShow: 5,
        arrows: true,
        responsive: [

            {
                breakpoint: 1544,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1062,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    });

    $('.country-whatinteresting-slider').slick({
        autoplay: true,
        slidesToShow: 4,
        arrows: true,
        adaptiveHeight: true,
        responsive: [

            {
                breakpoint: 1630,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },

            {
                breakpoint: 1278,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1096,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    });

    $('.profile-data-slider').slick({
        arrows: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    });

    $('.facts-slider').slick({
        autoplay: true,
        slidesToShow: 1,
        arrows: true,
        adaptiveHeight: true,
    });

    $('.hotels-slider').slick({
        autoplay: true,
        slidesToShow: 5,
        arrows: true,
        responsive: [

            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    });
    $('.blocks-blockwithlinks-slider').slick({
        autoplay: true,
        slidesToShow: 5,
        arrows: true,
        responsive: [

            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    });

    if($(heroVideo).length) {
        bootVideo();
    }



    btnUp();
    mobileMenu();
    accordion();
    btnUp();
    initializeDatepicker();
});

jQuery(document).ready(function($) {
    $('#forgot-password-link').on('click', function(e) {
        e.preventDefault();
        $('#login-form-container').hide();
        $('#password-reset-form').show();
    });

    $('#back-to-login-link').on('click', function(e) {
        e.preventDefault();
        $('#password-reset-form').hide();
        $('#login-form-container').show();
    });

    $('#loginform').on('submit', function(e) {
        e.preventDefault();
        var formData = $(this).serialize();
        $.ajax({
            type: 'POST',
            url: ajax_object.ajax_url,
            data: formData + '&action=custom_login',
            success: function(response) {
                if (response.success) {
                    window.location.href = response.data.redirect;
                } else {
                    $('#login-error').html(response.data);
                    $('#forgot-password-link').show();
                }
            }
        });
    });

    $('#reset-password-form').on('submit', function(e) {
        e.preventDefault();
        var formData = $(this).serialize();
        $.ajax({
            type: 'POST',
            url: ajax_object.ajax_url,
            data: formData + '&action=custom_reset_password',
            success: function(response) {
                if (response.success) {
                    $('#reset-password-message').html(response.data);
                } else {
                    $('#reset-password-message').html(response.data);
                }
            }
        });
    });
});

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};


